@import "~@flaticon/flaticon-uicons/css/all/all";

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  box-shadow: 4px 2px 10px 1px #00000038;
}


.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
  overflow-y: auto;
  max-height: 200px;
}

.css-9dmjx7-MuiPaper-root-MuiAlert-root{
  animation: alertFloat 5s ease-in-out both
}

@keyframes alertFloat {
  0% {
    transform: translateY(-48px);
    opacity: 0;
  }
  25% {
    transform: translateY(0px);
    opacity: 1
  }
  50% {
    transform: translateY(0px);
    opacity: 1
  }
  75% {
    transform: translateY(0px);
    opacity: 1
  }
  100%{
    transform: translateY(-48px);
    opacity: 0
  }
};

body{
  overflow: hidden !important;
}

header{
  height: 12%
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden !important;
}

.css-p4i41s-MuiButtonBase-root-MuiButton-root{
  max-width: 122px;
  font-size: 12px !important;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
